.experience-section {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow-wrap: normal;
}

.experience-header {
    font-size: 54px;
    padding-bottom: 1em;
    padding-top: 1.6em;
}

.position-details {
    display: inline-block;
}

.experience-title {
    font-size: 1.8em;
}

.experience-intro {
    padding: 0 12% 1.5em 12%;
    font-size: 1.4em;
}

.experience-img {
    max-width: 10em;
    max-height: 10em;
    width: auto;
    height: auto;
    vertical-align: middle;
    padding-bottom: 1.2em;
}

.experience-date {
    font-size: 1.3em;
    float: right;
    color: rgb(93, 63, 211);
    padding-top: 0.1em;
}

.at {
    font-size: 20px;
}

h3 { 
    font-size: 36px; 
    font-weight: normal; 
    line-height: 48px; 
    margin: 0; 
}

.experience-body {
    float: right;
    width: 60vw;
    text-align: left;
}

.experience-text {
    padding: 3px 0;
    font-size: 1.2em;
}

.on {
    padding: 0.2em;
    background-color: rgb(138,43,226, 0.18);
}

.off {
    color: black;
    padding: 0.2em;
}

.experience-tech {
    font-size: 1.2em;
    padding: 0.3em 0 1em 0;
}

.experience-item {
    margin: 2vw;
    padding: 2vw;
    border: 3px solid #4B0082;
    box-shadow: 6px 6px rgb(147,112,219, 0.7);
}

.toggle-expand {
    cursor: pointer;
    color: rgb(93, 63, 211);
}
.toggle-expand:hover {
    /* text-decoration: underline #8860d0; */
    border-bottom: 3px solid #8860d0;
}
