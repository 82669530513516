.blog {
    justify-content: center;
    align-items: center;
}

.headline {
    padding-top: 88px;
    padding-bottom: 18px;
    font-size: 54px;
}

.headline a {
    color: black;
}

.headline a:hover {
    color: #8860d0;
}

#medium-icon {
    height: 0.6em;
    vertical-align: auto;
    padding-left: 7px;
}

.intro {
    padding: 0 20vw 5vh 20vw;
    justify-content: center;
    text-align: center;
    font-size: 1.24em;
}

.blog-post {
    margin: 2%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.blog-post .picture {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.blog-post .picture img {
    max-width: 36vw;
}

.blog-title {
    padding: 6px;
    font-size: 18px;
    color: #5680e9;
}

.medium-caption {
    font-size: 1.5em;
    color: black;
    text-decoration: underline #8860d0;
}
