.menu-header {
    top: 0px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 99vw;
    height: 60px;
    background-color: rgb(147,112,219, 0.7);
    opacity: 1;
    box-shadow: 0 2px 3px #9370DB;
    padding-right: 1vw;
    z-index: 10;
}

.section-header {
    display: inline-block;
    font-size: 24px;
    padding: 18px;
    color: black;
}

.section-header:hover {
    text-decoration: underline #8860d0;
}