.projects-section {
    height: 100vh;
}

.projects-header {
    font-size: 54px;
    padding: 1.6em 0 1em 0;
}

.projects-intro {
    padding: 0 12% 2em 12%;
    font-size: 1.4em;
}

.projects {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
}

.card-header {
    background-color: rgb(147,112,250,0.6);
}

.card {
    margin: 1vw;
    width: 22vw;
}

.card:hover {
    transform: scale(1.05);
}

.media {
    height: 0;
    padding-top: 56.25%;
}

.tech li {
    list-style: none;
}
