.App {
  text-align: center;
} 

.App-header {
  height: 240px;
  margin-top: 212px;
  font-size: 54px;
}

.App-footer {
  margin-top: 8%;
}

.App-intro {
  font-size: large;
}

a {
  text-decoration: none;
}