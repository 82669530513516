.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    height: 100vh;
    margin: 0;
}

.about-inner {
    display: flex;
    margin: 12%;
}

.hi {
    font-size: 3vw;
    padding-bottom: 5vh;
    text-align: left;
}

.about-text {
    max-width: 49vw;
    font-size: 1.5vw;
    text-align: left;
    margin: 0.3em 0;
}

.about-section {
    padding: 1em 1em;
    box-sizing: content-box;
    border-radius: 1.6em;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: left;
}

#picture-of-me img {
    max-width: 32vw;
    margin-right: 5vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}
